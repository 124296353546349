*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-family: $font-family-base;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba($black, 0);
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
}

body,
body.mat-typography {
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left;
  //background-color: $secondary;
  background-image: linear-gradient(to right, #002139, #022842, #04304c, #083856, #0b4060);
}

p{
  color:$white;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */

::-webkit-scrollbar-track {
  background: #e5e5e5;
  border-radius: 20rem;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #c4c4e9;
  border-radius: 20rem;
}

a {
  color: $darkseafoam;
  text-decoration: $link-decoration;
  background-color: transparent;
  @include hover() {
    color: $darkseafoam;
    text-decoration: $link-hover-decoration;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
  @include hover() {
    color: inherit;
    text-decoration: none;
  }
}

ul,
ol {
  @include list-reset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $seafoam;
}

app-home,
app-claims-list,
app-recent-orders-list,
app-prescriptions,
app-cart,
app-cart-list,
app-orders,
app-claims,
app-drug,
app-drug-search,
app-drug-pricing,
app-find-pharmacy,
app-membership,
app-benefits,
app-settings,
app-help,
app-custom-forms,
app-clinical-reviews {
  width: 100%;
}

.mat-body,
.mat-body-1,
.mat-typography,
.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  font-family: $font-family-base;
  font-size: $font-size-base;
}

.mat-typography {
  section {
    h4 {
      @include font(0.9375rem, bold);
    }
  }
}

.main-container {
  padding-left: 1.5rem;
  width: 100%;
  padding-right: 0.2rem;
  height: 100%;
}
.membership-main-container {
  padding-left: 1.5rem;
  width: 98%;
  padding-right: 1.5rem;
  height: 100%;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.empty-box-height {
   min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  @include radius(1.25rem);
  text-align: center;
  height: calc(100% - 70px);
  @media screen and (min-height: 900px) and (min-width: 1600px) {
    min-height: 400px;
  }
  p {
    margin: 0;
    color: $secondary;
  }
  &.box-height{
    min-height: 450px;
  height: auto;
  @media screen and (min-height: 900px) and (min-width: 1600px) {
    min-height: 500px;
  img{
    width:250px;
  }
}
  }
}

:focus {
  outline: none;
}

.box-height{
  min-height: 450px;
height: auto;
}

app-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loader-circle {
  display: flex;
  justify-content: center;
  height: calc(100% - 42px);
  align-items: center;
}

.past-order-text {
  color: white;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}